$env: production;@import "@/_scl-corporate/assets/css/settings/_variables.scss";@import "@/_scl-corporate/assets/css/tools/_mixins.scss";
#app {
	.scl-card-image-selector {
		&__img {
			overflow: visible;
			padding: gutter-size(12.5) gutter-size(4) gutter-size(10);
		}
		&__card {
			margin: 0 -16px;
			z-index: zindex(level1);
		}
		&__image-tag {
			background-color: $color-orange;
			padding: gutter-size(2) gutter-size(4);
			position: absolute;
			top: gutter-size(10);
		}
		&__image-tag-name {
			color: $color-white;
			font-size: $font-size-base;
			line-height: 1.25;
			margin: 0;
		}
		&__tertiary-cta {
			display: flex;
			justify-content: flex-end;
			a {
				color: $color-white;
			}
			.scl-button {
				font-size: $font-size-base;
				min-width: auto;
				position: relative;
				&:hover {
					&:after {
						background-color: $color-orange;
					}
					& + i {
						color: $color-orange;
					}
				}
				&:after {
					background-color: $color-white;
					bottom: -1px;
					content: '';
					height: 1px;
					left: 0;
					position: absolute;
					transition: background-color 0.3s linear;
					width: 100%;
				}
			}
		}
		&__tertiary-cta-icon {
			color: $color-white;
			margin-left: gutter-size(3);
			z-index: 0;
		}
		&__img-overlay {
			background: linear-gradient(rgba($color-black, 0.45), rgba($color-black, 0.45));
			bottom: 0;
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
		}
		&__title-container {
			position: relative;
		}
		&__title {
			color: $color-white;
			margin: gutter-size(7.5) 0 gutter-size(6);
			max-width: 892px;
		}
		&__subtitle {
			color: $color-white;
			font-size: $font-size-base;
			max-width: 768px;
		}
		&__dropdown-container {
			display: flex;
			flex-direction: column;
			.v-menu__content {
				min-width: 100% !important;
				top: 100% !important;
			}
			.scl-card-image-selector__primary-cta {
				padding: gutter-size(4) gutter-size(6);
				width: max-content;
				i {
					margin-left: 28px;
				}
			}
		}
		&__selection {
			// overwrite vuetify select style
			.v-input__slot {
				background-color: $color-white;
				border: 1px solid $color-gray-600;
				border-radius: 0;
				font-size: $font-size-base;
				padding: gutter-size(4) gutter-size(6);
			}
			.v-input__append-inner {
				margin: auto;
			}
			.v-select__selection {
				color: $color-gray-600;
				line-height: 1.25;
				max-width: none;
				// remove vuetify default select placeholder
				& ~ input {
					height: 0;
					padding: 0%;
				}
			}
			.v-list__tile--link {
				&:hover {
					background: none;
					color: $color-orange;
				}
			}
		}

		@media (min-width: $md) {
			&__card {
				margin: 0;
			}
			&__img {
				padding: gutter-size(8.5) gutter-size(8.5) gutter-size(8) gutter-size(16);
			}
			&__tertiary-cta-icon {
				margin-left: gutter-size(5);
			}
			&__image-tag {
				top: gutter-size(18);
			}
			&__title {
				margin: gutter-size(18) 0 gutter-size(5);
			}
			&__dropdown-container {
				align-items: center;
				flex-direction: row;
				margin-top: gutter-size(7);

				.scl-card-image-selector__primary-cta {
					margin-left: gutter-size(6);
					padding: gutter-size(6.25) gutter-size(8) ;
					i {
						margin-left: gutter-size(10);
					}
				}
			}
			&__selection {
				max-width: 652px;
				// overwrite vuetify select style
				.v-input__slot {
					font-size: $font-size-lg;
					padding: gutter-size(4.5) gutter-size(6);
				}
			}
		}
	}
}
