$env: production;@import "@/_scl-corporate/assets/css/settings/_variables.scss";@import "@/_scl-corporate/assets/css/tools/_mixins.scss";
#app {
	.scl-subscribe-panel {
		&__title {
			color: $color-black;
			font-size: $font-size-lg;
		}
		&__sub-title {
			color: $color-black;
			margin: 8px 0;
		}
		&__image {
			margin: gutter-size(9.5) 0px;
		}
		@media (min-width: $md) {
			&__sub-title {
				margin: 24px 0;
			}
			&__image {
				height: 100%;
				margin: 0;
			}
		}
	}
}
