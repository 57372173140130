$env: production;@import "@/_scl-corporate/assets/css/settings/_variables.scss";@import "@/_scl-corporate/assets/css/tools/_mixins.scss";
#app {
	.scl-subscribe-form {
		background-color: #eaeaea;
		&__container {
			align-items: center;
			display: flex;
			height: 100%;
			p {
				color: $primary-color;
				font-family: $font-name-klavika-medium;
				font-weight: $font-weight-light;
				letter-spacing: 0.5px;
				line-height: 1.4;
				margin: 0;
			}
		}
		&__icon {
			color: #5b5b5b;
			float: left;
			font-size: 24px;
			margin-right: 10px;
		}
		.scl-input {
			margin-right: 20px;
			.v-input__slot {
				background-color: $color-white;
				border: 1px solid #cbcbce;
			}
			input {
				line-height: 48px;
				max-height: 20px;
				padding: 0 8px;
			}
		}
		.scl-button {
			margin-top: -6px;
		}

		/*
			CHHR-49 subscribe form variant
		*/
		&__variant {
			// overwrite .scl-subscribe-form background
			background: none;
			&--description {
				color: $primary-color;
				margin-bottom: 0;
			}
			&--wrapper {
				align-items: center;
				display: flex;
				flex-wrap: wrap;
				height: 100%;
			}
			&--input {
				margin-right: 0 !important;
				padding: 0;
				.v-input__slot {
					background-color: $color-white;
					border: 1px solid $color-gray-500;
					font-size: $font-size-lg;
					&:before {
						border: none;
					}
					&:after {
						bottom: -2px;
					}
				}
				.v-text-field__slot {
					padding: 10px;
					font-size: 18px;
				}
				input {
					line-height: 30px !important;
					padding: 0;
				}
			}
			&--icon {
				font-size: 30px;
			}
			&--btn {
				margin-top: 0 !important;
				width: 100%;
				padding: gutter-size(2.5) gutter-size(4);
				.v-icon {
					margin-left: 16px;
				}
			}
			.scl-button {
				font-size: 18px;
			}
			&--terms {
				color: $color-gray-600;
				font-size: 16px;
				font-style: italic;
			}
			&--terms-link {
				border-bottom: 1px solid $color-orange;
				color: $color-orange;
				font-style: normal;
				padding-bottom: 1px;
				text-decoration: none;
			}
			&--success-message {
				color: $primary-color;
				font-size: $font-size-lg;
			}

			@media (min-width: $lg) and (min-height: 730px) {
				&--description {
					font-size: $font-size-lg;
					margin-bottom: 24px;
				}
				&--wrapper {
					flex-wrap: nowrap;
				}
				&--input {
					margin-right: 16px !important;
					.v-text-field__slot {
						padding: 18px 24px;
						font-size: $font-size-xl;
					}
				}
				&--btn {
					margin-top: -6px;
					padding: gutter-size(4);
					width: auto;
				}
				&--terms {
					margin-top: 0;
				}
				.scl-button {
					font-size: $font-size-lg;
				}
				.scl-input {
					input {
						max-height: 48px;
					}
				}
			}
		}
	}
}
