/*! elements: typography */

@font-face {
	font-display: swap;
	font-family: 'Neue Hass Grotesk Display Pro';
	font-style: normal;
	font-weight: $font-weight-light;
	src: url('#{$font-path}/NeueHaasDisplayLight.woff') format('woff'), url('#{$font-path}/NeueHaasDisplayLight.woff2') format('woff2');
}

@font-face {
	font-display: swap;
	font-family: 'Neue Hass Grotesk Display Pro';
	font-style: italic;
	font-weight: $font-weight-light;
	src: url('#{$font-path}/NeueHaasDisplayLightItalic.woff') format('woff'), url('#{$font-path}/NeueHaasDisplayLightItalic.woff2') format('woff2');
}

@font-face {
	font-display: swap;
	font-family: 'Neue Hass Grotesk Display Pro';
	font-style: normal;
	font-weight: $font-weight-medium;
	src: url('#{$font-path}/NeueHaasDisplayMediu.woff') format('woff'), url('#{$font-path}/NeueHaasDisplayMediu.woff2') format('woff2');
}

@font-face {
	font-display: swap;
	font-family: 'Neue Hass Grotesk Display Pro';
	font-style: normal;
	font-weight: $font-weight-roman;
	src: url('#{$font-path}/NeueHaasDisplayRoman.woff') format('woff'), url('#{$font-path}/NeueHaasDisplayRoman.woff2') format('woff2');
}

// overwrite default vuetify text transform
* {
	text-transform: none;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5 {
	font-family: $font-name-neue-hass-display-pro;
	font-weight: $font-weight-light;
	line-height: $line-height-base;
}

h6, .h6 {
	font-family: $font-name-neue-hass-display-pro;
	font-weight: $font-weight-medium;
	line-height: $line-height-base;
}

h1, .h1 { font-size: $h1-font-size; }
h2, .h2 { font-size: $h2-font-size; }
h3, .h3 { font-size: $h3-font-size; }
h4, .h4 { font-size: $h4-font-size; }
h5, .h5 { font-size: $h5-font-size; }
h6, .h6 { font-size: $h6-font-size; }

@media (max-width: $md) {
	h1, .h1 { font-size: $h1-font-size-min; }
	h2, .h2 { font-size: $h2-font-size-min; }
	h3, .h3 { font-size: $h3-font-size-min; }
	h4, .h4 { font-size: $h4-font-size-min; }
	h5, .h5 { font-size: $h5-font-size-min; }
	h6, .h6 { font-size: $h6-font-size-min; }
}

a {
	color: $color-orange;
	font-weight: $font-weight-roman;
	&:hover {
		color: $color-orange-light;
	}
}
p {
	font-family: $font-name-neue-hass-display-pro;
	font-size: $font-size-base;
	line-height: $line-height-lg;

	a {
		color: $color-orange;
	}
}

.text-small {
	font-size: $font-size-base;
}
.text-large {
	font-size: $font-size-lg;
	@media (max-width: $md) {
		font-size: $font-size-base;
	}
}

mark {
	background-color: $color-orange-light;
}
