$env: production;@import "@/_scl-corporate/assets/css/settings/_variables.scss";@import "@/_scl-corporate/assets/css/tools/_mixins.scss";
#app {
	.scl-tab-carousel {
		&__carousel {
			box-shadow: none;
			.v-responsive__content {
				background: linear-gradient(75.96deg, rgba(0, 0, 0, 0.64) 0%, rgba(54, 54, 54, 0.1) 100%);
			}
			.v-carousel__controls {
				background: none;
				justify-content: flex-start;
				padding: 0 gutter-size(3.5);
				.v-btn {
					color: $color-gray-600;
					margin: 0 !important;
					.v-icon {
						opacity: 1;
					}
				}
				.v-btn--active {
					color: $color-orange;
					opacity: 1;
				}
			}
			@media (max-width: $md) {
				.v-window__container {
					height: auto !important;
				}
				.v-carousel__item {
					height: auto !important;
				}
			}
		}
		&__content {
			@media (max-width: $md) {
				margin-bottom: 38px;
			}
		}
		&__title {
			color: $color-white;
			font-family: $font-name-neue-hass-display-pro;
			font-size: 56px;
			font-weight: $font-weight-light;
			letter-spacing: 1.5px;
		}
		&__caption {
			color: $color-white;
			font-size: 16px;
			line-height: 1.5;
			margin: gutter-size(4) 0 gutter-size(10);
		}
		&__btn {
			margin-bottom: gutter-size(12.5);
			padding: gutter-size(6);
			#{$interact} {
				background-color: $color-orange-light !important;
			}
		}
		&__tab-container {
			padding: 0;
			.v-tabs__wrapper {
				border-bottom: 1px solid $color-gray-500;
			}
			.v-tabs__container {
				background: $color-white;
				height: 100%;
				justify-content: space-around;
				max-height: 144px;
				padding: 0 gutter-size(4);
			}
		}
		&__tabs-header {
			.v-tabs__slider {
				box-shadow: rgba($color-orange, 1) 0px 1px 0px;
				height: gutter-size(1);
			}
		}
		&__tab-header-item {
			.v-tabs__item {
				flex-direction: column;
				font-size: 24px;
				opacity: 1;
				padding: 30px 20px;
				width: 100%;
			}
		}
		&__tab-icon {
			color: $color-orange;
			margin-bottom: gutter-size(4);
		}
		&__tab-items {
			box-shadow: $shadow-box;
			padding: 0;
		}
		@media (min-width: $md) {
			&__title {
				font-size: $h1-font-size;
			}
			&__caption {
				font-size: $h5-font-size;
				p {
					font-size: $h5-font-size;
					margin-left: 0;
				}
			}
			&__btn {
				font-size: $h5-font-size;
				margin-bottom: 144px;
				padding: gutter-size(8);
			}
			&__tab-container {
				margin-top: -144px;
				padding: 0 gutter-size(6);
				.v-tabs__wrapper {
					border: 1px solid $color-gray-500;
				}
				.v-tabs__container {
					padding: 0;
				}
			}

			&__tabs-header {
				.v-tabs__slider {
					height: gutter-size(2);
				}
			}
			&__tab-header-item {
				#{$interact} {
					color: $color-orange;
				}
			}
			&__tab-icon {
				font-size: 40px;
			}
			&__tab-item {
				font-size: 24px;
				p {
					font-size: 24px;
				}
			}
			&__tab-items {
				border: 1px solid $color-gray-500;
				border-top: none;
				padding: gutter-size(4);
			}
		}
	}
}
