$env: production;@import "@/_scl-corporate/assets/css/settings/_variables.scss";@import "@/_scl-corporate/assets/css/tools/_mixins.scss";
#app {
	.scl-card-link-selector {
		display: flex;
		height: 100%;
		position: relative;
		text-decoration: none;
		&__card {
			cursor: initial;
			display: flex;
			flex-direction: column;
		}
		&__image {
			border-right: 0;
		}
		&__image-tag {
			background-color: $color-orange;
			margin: gutter-size(4) 0 0 gutter-size(4);
			padding: gutter-size(2) gutter-size(4);
			width: max-content;
		}
		&__image-tag-name {
			color: $color-white;
			font-size: $font-size-base;
			line-height: 1.25;
			margin: 0;
		}
		&__content {
			height: 100%;
			padding: gutter-size(4) gutter-size(6) gutter-size(0);
		}
		&__title {
			margin-bottom: gutter-size(4);
		}
		&__description {
			font-size: $font-size-base;
			margin-bottom: gutter-size(6);
		}
		&__link {
			font-size: $font-size-base;
			padding: 0 gutter-size(6) gutter-size(6);
		}
		&__selection {
			margin-top: auto;
			// overwrite vuetify select style
			.v-input__slot {
				border: 2px solid $color-gray-600;
				border-radius: 0;
				font-size: $font-size-base;
				padding: gutter-size(4);
			}
			.v-input__append-inner {
				margin: auto;
			}
			.v-list__tile--link {
				&:hover {
					background: none;
					color: $color-orange;
				}
			}
		}
		&__card-hover {
			background-color: $color-orange;
			height: 8px;
			transition: height timing(fast) $easing;
			will-change: height;
		}

		@media (min-width: $sm) {
			&__image-tag {
				margin: gutter-size(6) 0 0 gutter-size(6);
			}
			&__content {
				padding: gutter-size(10) gutter-size(10) gutter-size(0);
			}
			&__description {
				align-items: center;
				display: flex;
				font-size: $font-size-lg;
				margin-bottom: gutter-size(4);
			}
			&__link {
				font-size: $font-size-lg;
				padding: 0 gutter-size(10) gutter-size(8.5);
			}
			&__selection {
				max-width: 442px;
				// overwrite vuetify select style
				.v-input__slot {
					font-size: $font-size-lg;
				}
			}
			&__card-hover {
				bottom: 0;
				height: 0;
				position: absolute;
				width: 100%;
			}

			&:hover {
				cursor: pointer;
				transform: translateY(gutter-size(-4));
				.scl-card-link-selector__card-hover {
					height: gutter-size(4);
				}
			}
			&:active {
				text-decoration: none;
			}
		}
	}
}
