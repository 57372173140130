$env: production;@import "@/_scl-corporate/assets/css/settings/_variables.scss";@import "@/_scl-corporate/assets/css/tools/_mixins.scss";
#app {
	.scl-card-image {
		background-color: $color-white;

		&__card {
			&.align-bottom {
				.v-responsive__content {
					justify-content: flex-end;
				}
			}
		}

		&__img {
			padding: 32px 24px;
			position: relative;
		}

		.v-responsive__content {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}

		&__img-overlay {
			background: linear-gradient(180deg, transparent, $color-black);
			bottom: 0;
			left: 0;
			opacity: 0.5;
			position: absolute;
			right: 0;
			top: 0;
		}

		&__title-container {
			position: relative;
		}

		&__title {
			color: $color-white;
			font-family: $font-name-neue-hass-display-pro;
			font-size: 27px;
			line-height: 1em;
		}

		&__subtitle {
			color: $color-white;
			font-family: $font-name-neue-hass-display-pro;
			font-size: 23px;
			height: 50px;
			line-height: 1em;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			overflow: hidden;
			text-overflow: ellipsis;
			-webkit-box-orient: vertical;
			height: 38px;
			display: table-cell;
			vertical-align: middle;
		}

		.scl-button {
			align-self: flex-start;
			color: $color-white;
			font-size: 15px;
			line-height: 38px;
			margin-top: 21px;
			padding: 0 16px;
		}

		&--news {
			.scl-card-image__img-overlay {
				background: none;
			}

			.scl-card-image__img {
				padding: 0;
			}

			.scl-card-image__title-container {
				background: rgba($color-black, 0.6);
				padding: 16px;
			}

			.scl-card-image__title {
				font-size: 18px;
				letter-spacing: 1px;
				line-height: 24px;
			}

			.scl-card-image__subtitle {
				font-family: $font-name-neue-hass-display-pro;
				font-size: 18px;
				font-weight: $font-weight-light;
			}

			@media (min-width: $lg) {
				background: none;
				padding: 0%;
			}

			@media (min-width: $xl) {
				padding: 9%;
			}
		}

		/*
			CHHR-48 Carousel customer story variant
			variant includes:
			- background color
			- floating card panel
			- horizental layout
		*/
		&__story-variant {

			.scl-button {
				align-self: flex-start;
				border-color: $color-orange;
				color: $color-orange;
				font-size: 15px;
				line-height: 38px;
				margin-top: 21px;
				padding: 11px 53px;
			}

			&--bg-image {
				align-items: flex-end;
				padding-top: 150px;

				.v-image__image {
					max-height: 70%;
				}
			}

			&--panel {
				margin: 0 gutter-size(4) gutter-size(4);
				padding: gutter-size(6) gutter-size(4);

				background-color: $color-white;
			}

			&--panel-title {
				color: $primary-color;
				margin-bottom: 16px;
			}

			&--panel-description {
				color: $primary-color;
				font-size: $h5-font-size-min;
				font-weight: $font-weight-roman;
			}

			&--btn {
				color: $color-orange;

				&:hover {
					color: $color-orange-light;
				}
			}

			&--bg-colour {
				background-color: $color-secondary-green;
			}

			// overwrite vuetify default style

			.v-carousel {
				box-shadow: none;
			}
			.v-carousel__controls {
				background: none;

				.v-btn {
					color: $color-gray-500;

					i {
						font-size: 16px;
					}

					&--active {
						color: $color-secondary-green;
					}
				}
			}

			@media (min-width: $md) {
				&--bg-image {
					height: 768px;
					align-items: center;
					justify-content: right;
					padding-top: 0;


					.v-image__image {
						max-width: 60%;
						max-height: 100%;
					}

					.v-responsive__content {
						display: flex;
						flex-direction: column;
						align-items: flex-end;
					}
				}

				&--panel {
					display: flex;
					align-items: center;
					margin-right: 120px;
					padding: 48px 132px;
					width: 768px;
					height: 478px;
				}

				&--panel-title {
					margin-bottom: 24px;
				}

				&--panel-description {
					font-size: $font-size-xl;
					margin-bottom: 24px;
				}

			}
		}
	}
}