$env: production;@import "@/_scl-corporate/assets/css/settings/_variables.scss";@import "@/_scl-corporate/assets/css/tools/_mixins.scss";
// settings

@import 'settings/variables';

// tools

@import 'tools/mixins';

// libraries
@import 'libraries/vuetify';

// generic
html {
	font-family: $font-name-neue-hass-display-pro;
	scroll-behavior: smooth;
}

body {
	overflow-x: hidden;
}

.application {
	font-family: $font-name-neue-hass-display-pro;
}

// elements
@import 'elements/typography';

.application,
#app {
	transition: opacity timing(fast) $easing;
}

// objects

// components

@import 'components/coates-icons';

// can move components / styling into seperate files

// container overrides
#app {
	.container {
		max-width: 100%;
	}
	@media (min-width: $sm) {
		.container {
			max-width: 100%;
		}
		.container.fluid {
			padding: 0;
		}
	}
	@media (min-width: $md) {
		.container {
			max-width: 100%;
		}
		.container.fluid {
			padding: 0;
		}
	}
	@media (min-width: $lg) {
		.container {
			max-width: 1608px;
		}
		.container.fluid {
			max-width: 100%;
			padding: 0;
		}
	}
	@media (min-width: $xl) {
		.container {
			max-width: 1608px;
		}
		.container.fluid {
			max-width: 100%;
			padding: 0;
		}
	}
}

#app-main {
	@media (min-width: $md) {
		margin-top: 144px;
	}
}

// global form styling
#app {
	.scl-form {
		&__title {
			margin-bottom: 22px;
			font-size: 24px;
			font-family: $font-name-neue-hass-display-pro;
			line-height: 1.4;
			letter-spacing: 1px;
			text-align: center;
		}
		label,
		&__label {
			display: block;
			margin-bottom: 8px;
			color: $primary-color;
			font-weight: $font-weight-medium;
			font-size: 14px;
			font-family: $font-name-neue-hass-display-pro;
			letter-spacing: 1px;
			cursor: pointer;
			span {
				font-weight: $font-weight-light;
				font-family: $font-name-neue-hass-display-pro;
				text-transform: capitalize;
				opacity: 0.6;
			}
		}
		input,
		&__input {
			.v-input__slot {
				min-height: 48px;
				background: $color-white !important;
				border: 1px solid #cbcbce;
				border-radius: 0;
				box-shadow: inset 0 1px 2px hsla(0, 0%, 4%, 0.1);
				&:before {
					display: none;
				}
				input {
					color: #0a0a0a;
				}
			}
			.v-text-field__slot {
				align-items: center;
				input,
				textarea {
					margin: 0;
				}
			}
		}
		&__select {
			.v-icon {
				height: 16px;
				color: $color-orange;
				font-size: 16px;
			}
			.v-select__selections {
				padding-top: 0;
			}
		}
		&__input--location {
			.v-select__slot {
				display: flex;
				align-items: center;
				background: $color-white !important;
				border: 1px solid #cbcbce;
				border-radius: 0;
				box-shadow: inset 0 1px 2px hsla(0, 0%, 4%, 0.1);
				&:before {
					display: none;
				}
				input {
					align-self: unset;
					margin-top: 0;
					color: #0a0a0a;
				}
			}
			.v-input__icon--append {
				display: none;
			}
			.v-icon {
				height: 16px;
				font-size: 16px;
			}
		}
		&__checkbox {
			.v-input__slot {
				background: none !important;
				border: 0;
				box-shadow: none;
			}
			.v-icon {
				color: #cbcbce;
			}
		}
		button,
		&__btn {
			height: 56px;
			margin: 0 auto 20px;
			font-weight: $font-weight-medium;
			font-size: 17px;
			font-family: $font-name-neue-hass-display-pro;
			line-height: 1.1;
			letter-spacing: 1.5px;
			cursor: pointer;
		}
		&__terms {
			padding: 0 20px;
			color: $primary-color;
			font-weight: $font-weight-light;
			font-size: 11px;
			line-height: 20px;
			letter-spacing: 0.5px;
			text-align: center;
			a {
				color: $color-orange;
				text-decoration: none;
				#{$interact} {
					color: #db3c00;
				}
			}
		}
		@media (min-width: $tablet) {
			&__title {
				margin-bottom: 26px;
				font-size: 36px;
				text-align: left;
			}
		}
	}
}

// board of directors sidebar
#app {
	.scl-about-sidebar {
		margin: 0;
		padding: 0;
		list-style: none;
		li {
			display: block;
			font-weight: $font-weight-light;
			font-size: 13px;
			font-family: $font-name-neue-hass-display-pro;
			letter-spacing: 0.5px;
			background-color: #f2f2f2;
			border-bottom: 1px solid #cbcbce;
			border-left: 4px solid #f2f2f2;
			&:last-of-type {
				border-bottom: 0;
			}
		}
		a {
			display: block;
			padding: 20px 16px;
			color: $primary-color;
			text-decoration: none;
			#{$interact} {
				cursor: pointer;
			}
		}
		.is-active {
			background-color: #eaeaea;
			border-left: 4px solid $color-orange;
			a {
				color: $color-orange;
			}
		}
	}
}

// utilities

@media (max-width: $md) {
	iframe[src*="youtube"],
	iframe[src*="vimeo"]{
		width: 100% !important;
	}
	
	img {
		height: auto !important;
		max-width: 100% !important;
	}
}


.overflow-y-hidden {
	max-height: 100vh;
	overflow: hidden;
	> body {
		max-height: 100vh;
		overflow: hidden;
	}
}

[v-cloak] {
	display: none;
}

.scl-spacer {
	height: 10px;
}
@media (min-width: $tablet) {
	.scl-spacer {
		height: 100px;
	}
}

.concrete-bg {
	position: relative;
	z-index: zindex(level1);
	&:before {
		position: absolute;
		top: 0;
		right: 50%;
		bottom: 0;
		z-index: zindex(ground);
		width: 100vw;
		background: #e5e5e5 url('/assets/img/flat-grey.jpg') repeat;
		transform: translateX(50%);
		content: '';
	}
}
