$env: production;@import "@/_scl-corporate/assets/css/settings/_variables.scss";@import "@/_scl-corporate/assets/css/tools/_mixins.scss";
#app {
	.scl-contact-form {
		&__call {
			background: $primary-color;
			border: 2px solid $color-orange;
			color: $color-white;
			display: block;
			text-align: center;
			text-decoration: none;
			.v-icon {
				font-size: 13px;
				line-height: 16px;
				margin-right: 8px;
			}
			span {
				font-family: $font-name-neue-hass-display-pro;
				font-size: 17px;
				letter-spacing: 1px;
				line-height: 44px;
			}
		}
		&__list {
			background: none;
			margin-top: 16px;
			.v-list__tile {
				padding: 0;
			}
			.v-list__tile__action {
				min-width: 30px;
			}
			.v-icon {
				color: $color-orange;
				font-size: 12px;
			}
			.v-list__tile__title {
				font-size: 14px;
			}
		}
		.theme--light.v-sheet {
			background: transparent;
			box-shadow: none;
		}
		.scl-form__input--location {
			.v-select__slot {
				border: 0;
				box-shadow: none;
			}
		}
		.scl-form__btn {
			border-radius: 0;
		}
		label {
			color: $primary-color !important;
			&.error--text {
				color: $primary-color !important;
			}
		}
		.v-text-field__details,
		.v-messages__message {
			height: 15px;
			line-height: 1.1;
		}
		.error--text {
			color: $color-orange !important;
		}
		@media (min-width: $tablet) {
			&__call {
				align-items: center;
				background: none;
				border: 0;
				color: $primary-color;
				display: inline-flex;
				flex-direction: row;
				text-align: left;
				.v-icon {
					font-size: 17px;
				}
				span {
					font-size: 28px;
					line-height: 34px;
				}
			}
		}
	}
}
