$env: production;@import "@/_scl-corporate/assets/css/settings/_variables.scss";@import "@/_scl-corporate/assets/css/tools/_mixins.scss";
#app {
	.scl-card-testimonial {
		&__datetime {
			color: $primary-color;
			font-size: $font-size-base;
		}
		&__testimonial-text {
			color: $primary-color;
			font-size: $font-size-lg;
		}
		&__testimonial-author {
			color: $primary-color;
			font-size: $font-size-base;
			font-weight: $font-weight-medium;
		}
		// overwrite vuetify default carousel
		.v-carousel {
			box-shadow: none;
		}
		.v-carousel__controls {
			background: none;
			.v-btn {
				color: $color-gray-500;
				i {
					font-size: 16px;
				}
				&--active {
					color: $color-orange;
				}
			}
		}
		@media (min-width: $md) {
			&__datetime {
				margin-bottom: 24px;
			}
			&__testimonial-text {
				font-size: $font-size-xl;
				margin-bottom: 24px;
			}
		}
	}
}
