// tools/mixins

@mixin clearfix() {
	&::after {
		clear: both;
		content: "";
		display: block;
	}
}

@function str-replace($string, $search, $replace: "") {
	$index: str-index($string, $search);
	@if $index {
		@return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
	}
	@return $string;
}

$fa-icons: (
	'arrow-right': '\f061'
);

@mixin fa-icon($icon: '') {
	align-items: center;
	content: map-get($fa-icons, $icon);
	display: inline-flex;
	font-family: 'Font Awesome 5 Pro';
}
