$env: production;@import "@/_scl-corporate/assets/css/settings/_variables.scss";@import "@/_scl-corporate/assets/css/tools/_mixins.scss";
#app {
	.scl-file-upload {
		&__dragbox {
			align-items: center;
			color: $primary-color;
			cursor: pointer;
			display: flex;
			flex-direction: column;
			justify-content: center;
			margin: 0 0 20px;
			outline: 1px dashed rgba(51, 51, 51, 0.3);
			padding: 14px;
			position: relative;
			text-align: center;
		}
		&__filelist {
			padding: 10px;
		}
		.scl-form__btn {
			height: 42px;
			margin-bottom: 0;
		}
		@media (min-width: $tablet) {
			&__dragbox {
				min-height: 160px;
			}
		}
	}
}
