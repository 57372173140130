$env: production;@import "@/_scl-corporate/assets/css/settings/_variables.scss";@import "@/_scl-corporate/assets/css/tools/_mixins.scss";
#app {
	.scl-card-solid {
		background-color: $color-white;
		&__card {
			height: 160px;
			position: relative;
		}
		&__img {
			height: 100%;
		}
		&__panel {
			align-items: center;
			background-color: $color-orange;
			bottom: 0;
			display: flex;
			height: 100%;
			justify-content: center;
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
			transition: background-color timing(faster) $easing;
			&:hover {
				background-color: rgba($color-black, 0.5);
			}
		}
		&__panel-container {
			padding: 5px;
			text-align: center;
		}
		&__icon {
			filter: brightness(0) invert(1);
			font-size: 40px;
			margin-bottom: 8px;
		}
		&__title {
			color: $color-white;
			font-family: $font-name-neue-hass-display-pro;
			font-size: 13px;
			font-weight: $font-weight-light;
			letter-spacing: 1.5px;
			line-height: 16px;
			text-align: center;
		}
		&__desc {
			color: $color-white;
			font-family: $font-name-neue-hass-display-pro;
			font-size: 14px;
			font-weight: $font-weight-light;
			line-height: 24px;
			margin-top: 16px;
		}
		@media (min-width: $sm) {
			&__card {
				height: 272px;
			}
		}
		@media (min-width: $tablet) {
			&__card {
				height: 356px;
			}
			&__panel-container {
				padding: 20px;
			}
			&__icon {
				font-size: 56px;
				margin-bottom: 20px;
				&:before {
					height: 56px;
					width: 56px;
				}
			}
			&__title {
				font-size: 23px;
				line-height: 24px;
			}
		}
		@media (min-width: $md) {
			&__card {
				height: 288px;
			}
		}
		@media (min-width: $lg) {
			&__card {
				height: 359px;
			}
		}
		@media (min-width: $lg) {
			&__card {
				height: 364px;
			}
		}
	}
}
