$env: production;@import "@/_scl-corporate/assets/css/settings/_variables.scss";@import "@/_scl-corporate/assets/css/tools/_mixins.scss";
#app {
	.scl-card {
		&__card {
			background-color: #eaeaea;
			box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
		}

		&__img {
			max-height: 452px;
			opacity: 1;
			transition: opacity timing(faster) $easing;

			#{$interact} {
				opacity: 0.8;
			}

			.v-image__image {
				background-color: $color-white;
				background-size: auto 100%;
			}
		}

		&__title-container {
			min-height: 137px;
			padding: 16px;

			p {
				color: $primary-color;
				font-family: $font-name-neue-hass-display-pro;
				font-size: 17px;
				font-weight: $font-weight-light;
				letter-spacing: 0.5px;
				margin-bottom: 16px;
				min-height: 51px;
			}
		}

		&__title-anchor {
			display: block;
			margin-bottom: 16px;
			padding: 0;
			text-decoration: none;
			width: 100%;
		}

		&__title {
			color: $primary-color;
			font-family: $font-name-neue-hass-display-pro;
			font-size: 18px;
			font-weight: $font-weight-medium;
			letter-spacing: 1px;
			line-height: 22px;

			#{$interact} {
				color: $color-orange;
				cursor: pointer;
			}

			&.with-description {
				color: $color-orange;

				#{$interact} {
					color: $primary-color;
				}
			}
		}

		&__actions {
			padding: 0 16px 16px;
		}

		&__accordion {
			box-shadow: none;
		}

		&__accordion-container {
			background: none;
		}

		.v-expansion-panel__header {
			color: $color-orange;
			font-family: $font-name-neue-hass-display-pro;
			font-size: 12px;
			font-weight: $font-weight-medium;
			letter-spacing: 1px;
			line-height: 15px;
			min-height: 38px;
			padding: 0;
		}

		&__accordion-item {
			background: none;
			box-shadow: none;
		}

		&__accordion-item-container {
			padding: 0;
		}

		&__accordion-anchor {
			color: $primary-color;
			display: block;
			font-family: $font-name-neue-hass-display-pro;
			font-size: 15px;
			font-weight: $font-weight-light;
			line-height: 24px;
			padding: 5px 0;
			text-decoration: none;

			#{$interact} {
				text-decoration: underline;
			}
		}

		@media (min-width: $lg) {
			padding: 0%;
		}

		// board of directors
		&--board {
			padding: 0;

			.scl-card__card {
				background-color: #fafafa;
				box-shadow: none;
			}

			.scl-card__img {
				pointer-events: none;
			}

			.scl-card__title-container {
				min-height: auto;
				padding-left: 0;
				padding-right: 0;
			}

			.scl-card__title {
				pointer-events: none;
				width: 100%;
			}

			p {
				margin: 0;
				min-height: auto;
			}
		}

		/*
			CHHR-48 testimonial card variant
		*/
		&__variant {
			&--datetime {
				color: $primary-color;
				font-size: $font-size-base;
			}

			&--testimonial-text {
				color: $primary-color;
				font-size: $font-size-lg;
			}

			&--testimonial-author {
				color: $primary-color;
				font-size: $font-size-base;
				font-weight: $font-weight-medium;
			}

			@media (min-width: $md) {
				&--datetime {
					margin-bottom: 24px;
				}

				&--testimonial-text {
					font-size: $font-size-xl;
					margin-bottom: 24px;
				}
			}
		}
	}

	@media (min-width: $sm) {
		.product-catalogue .scl-card__title {
			font-size: 18px;
		}
	}
	
	@media (max-width: $md) {
		.scl-card__title-container {
			min-height: 113px;
		}
	}
}