$env: production;@import "@/_scl-corporate/assets/css/settings/_variables.scss";@import "@/_scl-corporate/assets/css/tools/_mixins.scss";
#app {
	.scl-subscribe-modal {
		&__header {
			display: flex;
			justify-content: flex-end;
			padding: gutter-size(3.5) gutter-size(5) 0;
			&-icon {
				color: $primary-color;
				font-size: $font-size-lg;
				@media (min-width: $md) {
					font-size: $font-size-xl;
				}
			}
		}
		&__sub-title {
			margin-bottom: gutter-size(4);
		}
		&__content {
			padding: 0 gutter-size(6) gutter-size(10);

			.v-messages {
				min-height: 0;
			}
			@media (min-width: $md) {
				margin: 0 auto;
				max-width: 70%;
				padding: 0 0 gutter-size(16);
			}
		}
	}
}
