$env: production;@import "@/_scl-corporate/assets/css/settings/_variables.scss";@import "@/_scl-corporate/assets/css/tools/_mixins.scss";
#app {
	.scl-branch-autocomplete {
		background-color: $primary-color;
		border-bottom: 2px solid #5b5b5b;
		padding: 20px 0;
		&__input {
			align-items: center;
			flex-direction: column;
			padding: 0;
			input {
				align-self: center;
				font-family: $font-name-neue-hass-display-pro;
				padding-left: 8px;
			}
			.v-input__slot {
				margin-bottom: 0;
			}
			.v-text-field__details {
				display: none;
			}
		}
		&__label-container {
			text-align: right;
		}
		&__label {
			color: $color-white;
			display: block;
			font-family: $font-name-neue-hass-display-pro;
			font-size: 20px;
			letter-spacing: 1px;
			line-height: 1.8;
			text-align: center;
		}
		&__label-icon {
			color: $color-white;
			font-size: 22px;
			margin-right: 10px;
		}
		@media (min-width: $md) {
			padding: 22px 0;
			&__input {
				flex-direction: row;
			}
			.v-input__prepend-outer {
				width: 50%;
			}
		}
	}
}
