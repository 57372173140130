$env: production;@import "@/_scl-corporate/assets/css/settings/_variables.scss";@import "@/_scl-corporate/assets/css/tools/_mixins.scss";
#app {
	.scl-enquire-panel {
		background-color: $color-white;
		bottom: 0;
		box-shadow: 0 -2px 2px 0 rgba($color-black, 0.2);
		display: none;
		left: 0;
		position: fixed;
		right: 0;
		z-index: zindex(level5);
		&.is-visible {
			display: block;
		}
		&__title {
			color: $primary-color;
			font-family: $font-name-neue-hass-display-pro;
			font-size: 20px;
			margin-bottom: 20px;
		}
		&__btn {
			font-family: $font-name-neue-hass-display-pro;
			font-size: 17px;
			font-weight: $font-weight-medium;
			height: 52px;
			letter-spacing: 1.5px;
			margin: 0;
			opacity: 1;
			transition: opacity timing(faster) $easing;
			width: 100%;
			#{$interact} {
				opacity: 0.5;
			}
		}
		@media (min-width: $sm) {
			&__title {
				margin: 14px 0 0;
			}
			&__btn {
				float: right;
				padding: 0 48px;
				width: auto;
			}
		}
		@media (min-width: $md) {
			&__btn {
				padding: 0 58px;
			}
		}
	}
}
