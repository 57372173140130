$env: production;@import "@/_scl-corporate/assets/css/settings/_variables.scss";@import "@/_scl-corporate/assets/css/tools/_mixins.scss";
#app {
	.scl-tab-content {
		&__content {
			@media (max-width: $md) {
				margin-bottom: 38px;
			}
		}
		&__title {
			color: $color-white;
			font-family: $font-name-neue-hass-display-pro;
			font-size: 56px;
			font-weight: $font-weight-light;
			letter-spacing: 1.5px;
		}
		&__caption {
			color: $color-white;
			font-size: 16px;
			line-height: 1.5;
			margin: gutter-size(4) 0 gutter-size(10);
		}
		&__btn {
			margin-bottom: gutter-size(12.5);
			padding: gutter-size(6);
			#{$interact} {
				background-color: $color-orange-light !important;
			}
		}
		&__tab-container {
			padding: 0;
			.v-tabs__wrapper {
				border-bottom: 1px solid $color-gray-500;
			}
			.v-tabs__container {
				background: $color-white;
				height: 100% !important;
				justify-content: space-around;
				padding: 0 gutter-size(4);
			}
		}
		&__tabs-header {
			.v-tabs__slider {
				height: gutter-size(1);
			}
		}
		&__tab-header-item {
			height: 100%;

			.v-tabs__item {
				flex-direction: column;
				font-size: 24px;
				opacity: 1;
				padding: 30px 20px;
				width: 100%;
				height: 100%;
			}
		}
		&__tab-tile {
			height: 100%;
		}
		&__tab-icon {
			color: $color-orange;
			margin-bottom: gutter-size(4);
		}
		&__tab-items {
			padding: 0;
		}

		&__nav {
			padding-top: gutter-size(14);
		}

		&__nav-btn {
			width: gutter-size(30.5);
			height: gutter-size(12);
			.v-icon {
				margin: 0 gutter-size(2) !important;
				font-size: $h6-font-size-min;
			}
			&--right {
				float: right;
			}
			&--left {
				float: left;
			}
		}

		// tab content
		&__ww {
			padding: gutter-size(10);
			font-size: $h6-font-size-min;
			h5 {
				padding: gutter-size(4) 0;
			}
			ol {
				border-top: solid 1px $color-gray-600;
				padding: gutter-size(2) 0;
				list-style-type: none;
			}
			li {
				padding: gutter-size(2) 0;
			}
			a {
				color: $color-orange !important;
				text-decoration: none;
			}
			a:hover {
				opacity: 0.8;
			}
			.v-icon {
				margin: 0 gutter-size(1);
				color: $color-orange !important;
				font-size: $h6-font-size-min;
			}
		}
		// Rich text editor overrides
		iframe {
			border: 0;
			top: 0;
			width: 100%;
			left: 0;
			position: relative;
		}
		img {
			width: 100% !important;
		}
		// Accordion (mobile)
		&__accordion-title {
			font-family: $font-name-neue-hass-display-pro;
			font-size: $h6-font-size;
			line-height: 1;
			padding: gutter-size(3.5) 0;
		}
		.v-expansion-panel {
			box-shadow: none;
		}
		.v-expansion-panel__body {
			.flex {
				padding: 0;
			}
			.v-card__text {
				padding: gutter-size(6) gutter-size(4);
			}
		}
		.v-expansion-panel__container {
			margin-bottom: gutter-size(2);
			border: 1px solid $color-gray-300;
			&:last-child {
				margin-bottom: 0;
			}
		}
		.v-expansion-panel__header {
			margin: 0 gutter-size(4);
			padding: 0;
		}
		.v-expansion-panel__container--active {
			.v-expansion-panel__header {
				border-bottom: 1px solid $color-gray-500;
			}
			.scl-tab-content__accordion-title {
				color: $color-orange;
			}
		}
		.v-expansion-panel__header__icon {
			margin-right: gutter-size(-1);
			.v-icon {
				color: $color-orange !important;
			}
		}
		.v-card__text {
			h5 {
				padding: gutter-size(4) 0;
			}
			ol {
				border-top: solid 1px $color-gray-600;
				padding: gutter-size(2) 0;
				list-style-type: none;
			}
			li {
				padding: gutter-size(2) 0;
			}
			a {
				color: $color-orange !important;
				text-decoration: none;
			}
			a:hover {
				opacity: 0.8;
			}
			.v-icon {
				margin: 0 gutter-size(1);
				color: $color-orange !important;
				font-size: $h6-font-size-min;
			}
		}

		@media (min-width: $md) {
			&__title {
				font-size: $h1-font-size;
			}
			&__caption {
				font-size: $h5-font-size;
				p {
					font-size: $h5-font-size;
					margin-left: 0;
				}
			}
			&__btn {
				font-size: $h5-font-size;
				margin-bottom: 144px;
				padding: gutter-size(8);
			}
			&__tab-container {
				padding: 0;
				.v-tabs__wrapper {
					border: 1px solid $color-gray-500;
				}
				.v-tabs__container {
					padding: 0;
				}
			}

			&__tabs-header {
				.v-tabs__slider {
					height: gutter-size(2);
				}
			}
			&__tab-header-item {
				#{$interact} {
					color: $color-orange;
				}
			}
			&__tab-icon {
				font-size: 40px;
			}
			&__tab-item {
				font-size: 24px;
				p {
					font-size: 24px;
				}
			}
			&__tab-items {
				border: 1px solid $color-gray-500;
				border-top: none;
				padding: gutter-size(4);
			}
		}
	}
}
