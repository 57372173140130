$env: production;@import "@/_scl-corporate/assets/css/settings/_variables.scss";@import "@/_scl-corporate/assets/css/tools/_mixins.scss";
#app {
	.scl-card-icon {
		@media (min-width: $md) {
			height: 100%;
		}

		&__card {
			background: none;
			display: flex;
			flex-direction: column;
		}

		&__icon {
			font-size: 48px;
		}

		&__link-icon {
			margin-left: 8px;
		}

		&__container {
			width: 100%;
			height: 100%;
			padding: 0;

			>div {
				width: 100%;
				height: 100%;
			}
		}

		&__link-container {
			height: 100%;
			color: $color-black;
			display: flex;
			flex-direction: column;
			text-decoration: none;

			&--without-link {
				pointer-events: none;
			}

			&--with-link {
				color: $color-orange;

				.scl-card-icon__title {
					margin-top: 24px;
				}

				.scl-card-icon__title-text {
					border-bottom: 1px solid $color-orange;
				}

				&:hover {
					color: $color-orange-light;

					.scl-card-icon__link-icon {
						color: $color-orange-light  !important;
					}

					.scl-card-icon__icon {
						filter: opacity(0.6);
					}
				}
			}
		}

		&__title {
			display: flex;
			justify-content: center;
			margin: 16px 0;
		}

		&__copy {
			height: 100%;
			color: $primary-color;
			margin: 0;

		}

		&__link {
			color: $color-orange;
			display: block;
			margin-top: 16px;
		}

		// SIDE ICON VARIANT
		&__card--side-icon {
			height: 100%;

			.scl-card-icon__link-container {
				height: auto;
			}

			.scl-card-icon__container {
				display: flex;
				align-items: flex-start;
				height: 100%;

				>div {
					display: flex;
					flex-direction: column;
				}
			}

			.v-image,
			.v-icon {
				margin-bottom: gutter-size(6);
				margin-right: gutter-size(6);
			}

			.v-icon {
				font-size: 48px;

				&:before {
					max-width: 48px;

				}

			}

			.scl-card-icon__title {
				justify-content: flex-start;
				margin: 0 0 gutter-size(6);
			}

			.scl-card-icon__copy {
				padding-bottom: gutter-size(6);
				border-bottom: 1px solid $color-gray-600;
			}
		}

		@media (min-width: $sm) {
			&__card--side-icon {
				.scl-card-icon__container {
					flex-wrap: nowrap;
				}

				.v-image,
				.v-icon {
					margin-bottom: 0;
				}

				.scl-card-icon__copy {
					padding-bottom: gutter-size(10);
				}
			}

			&__title {
				margin: 40px 0 16px;
			}

			&__link-container {
				&--without-link {
					.scl-card-icon__icon {
						font-size: 80px;
						max-height: 120px;
					}
				}

				&--with-link {
					.scl-card-icon__icon {
						font-size: 64px;
						max-height: 120px;
						line-height: 120px;
					}
				}
			}
		}

		@media (min-width: $lg) {
			&__card--side-icon {
				.scl-card-icon__copy {
					font-size: 20px;

					p {
						font-size: 20px;
					}
				}
			}
		}

		// style only apply to mobile
		@media (max-width: $sm) {
			&__card {
				margin-top: 12px;
			}
		}
	}
}