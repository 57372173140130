$env: production;@import "@/_scl-corporate/assets/css/settings/_variables.scss";@import "@/_scl-corporate/assets/css/tools/_mixins.scss";
#app {
	.scl-select {
		margin: 24px auto;
		max-width: 768px;
		label {
			color: $primary-color;
			font-family: $font-name-neue-hass-display-pro;
		}
		.v-list__tile__title {
			color: $primary-color;
			font-family: $font-name-neue-hass-display-pro;
		}
		.v-text-field__details {
			display: none;
		}
		label {
			line-height: 13px;
		}
		.v-input__append-inner {
			margin-top: 13px;
		}
	}
}
