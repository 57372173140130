$env: production;@import "@/_scl-corporate/assets/css/settings/_variables.scss";@import "@/_scl-corporate/assets/css/tools/_mixins.scss";
#app {
	.scl-card-cta {
		background-color: $color-orange;
		display: flex;
		min-height: 405px;
		padding: 36px;
		&__card {
			background-color: transparent;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}
		&__title {
			color: $color-white;
			h5 {
				font-family: $font-name-neue-hass-display-pro;
				font-size: 35px;
				letter-spacing: 1px;
				line-height: 38px;
				margin-bottom: 36px;
			}
		}
		&__cta {
			color: $color-white;
			a {
				color: $color-white;
				text-decoration: none;
			}
			span {
				display: block;
				font-family: $font-name-neue-hass-display-pro;
				font-size: 15px;
				font-weight: $font-weight-roman;
				letter-spacing: 1px;
				line-height: 19px;
				margin-bottom: 5px;
			}
			.v-icon {
				color: $color-white;
			}
			h6 {
				align-items: center;
				display: flex;
				font-family: $font-name-neue-hass-display-pro;
				font-size: 35px;
				letter-spacing: 1px;
				line-height: 43px;
			}
		}
	}
}
