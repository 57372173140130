$env: production;@import "@/_scl-corporate/assets/css/settings/_variables.scss";@import "@/_scl-corporate/assets/css/tools/_mixins.scss";
#app {
	.scl-card--vertical {
		flex: 1 1 0;
		height: 100%;
		.v-card {
			height: 100%;
		}
	}
}
