$env: production;@import "@/_scl-corporate/assets/css/settings/_variables.scss";@import "@/_scl-corporate/assets/css/tools/_mixins.scss";
































































































































.scl-pagination-carousel {
	position: relative;

	.v-responsive__content {
		display: grid;
		grid-column-gap: 16px;
		grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
		justify-content: center;
	}

	.v-carousel {
		box-shadow: none;
		padding: 0 0 64px;

		&__next,
		&__prev {
			@media (max-width: $sm) {
				transform: translateY(calc(-100% - 8px));
				top: 100%;
				z-index: 2;
			}
		}

		&__controls {
			background: transparent;

			.v-btn--active {
				color: $color-orange;

				.v-icon {
					opacity: 1;
				}
			}

			@media (max-width: $sm) {
				display: none;
			}
		}
	}

	&__controls {
		bottom: 0;
		height: 32px;
		position: absolute;
		text-align: center;
		width: 100%;

		@media (min-width: $sm) {
			display: none;
		}
	}
}
